export default {
  proxy_path: "https://mdw2n8twz5.execute-api.eu-west-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://t40i9udhmk.execute-api.eu-west-1.amazonaws.com/api",
  google_api_key: "AIzaSyD84bsWJslpBGrsiinmyTx5aLPpbXFz0a8",
  main_company_term_id: "6",
  crm_base_url: "europe.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.europe.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.europe.forwoodsafety.com",
    APP_CLIENT_ID: "1co5a6a5sq1qtjoiqhodva7osb",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://tgxm8tvnfg.execute-api.eu-west-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.europe.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
